import { Injectable } from '@angular/core';
import {
  IAutocompleteProduct,
  ILotTemplates,
  IOrder, ISpecificationPriceHistory, ISpecificationPriceHistoryItem,
  ITableColumn,
  IUser,
  LotDashboardColumn,
  OffersHistory,
  Notifications
} from '../models';
import { Observable, Subject } from 'rxjs';
import {
  IAtpPaginationDynamicTable,
  IAtpEntity,
  IAtpAddEditInfo,
  IAtpSelectItem,
  IAtpFilterInfo,
  Guid,
  IAtpHistory,
  IAtpPagination, IAtpHandbook, IAtpHandbookGroup
} from '../atp-core/atp-core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AtpHttpService } from '../atp-core/services/atp-http.service';
import { AtpNodeTree } from '../atp-core/atp-dynamic-table-full-crud/atp-movement-tree/atp-movement-tree.component';
import { ITwoFactorQrCodeViewModel } from '../profile/two-factor-enabled/two-factor-enabled.component';
import { IWorkingDays } from '../components/working-days/working-days.component';

@Injectable({
  providedIn: 'root'
})
export class HttpService extends AtpHttpService<IUser> {
  constructor(http: HttpClient) {
    super(http);
    if (!this.user && !this.anonymousKey) {
      window.localStorage.setItem('anonymousKey', Guid.newGuid().toString());
    }
  }

  get anonymousKey(): string {
    return window.localStorage.getItem('anonymousKey');
  }

  protected baseAddress = environment.host + 'api/';

  protected get authHttpHeaders(): HttpHeaders {
    return new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
  }

  auth(login: string, password: string, formData: FormData): Observable<IUser> {
    const headers = new HttpHeaders().set('Authorization', 'Basic ' + btoa(login + ':' + password));
    return this.http.post<IUser>(this.baseAddress + 'login/auth', formData, { headers });
  }

  authEvent = new Subject<any>();

  reauth(): Observable<IUser> {
    if (!this.user) return null;
    const headers = new HttpHeaders().set('Authorization', 'Reauth ' + btoa(this.user.login + ':' + this.refreshToken));
    return this.http.post<IUser>(this.baseAddress + 'login/reauth', null, { headers });
  }

  resendConfirmEmail(userId: number, email: string): Observable<string> {
    return this.http.get<string>(this.baseAddress + 'login/resendconfirmemail' + (userId ? '?userId=' + userId : '?email=' + email));
  }

  confirmEmail(userId: number, code: string): Observable<Boolean> {
    return this.http.get<Boolean>(this.baseAddress + 'login/confirmemail/' + userId + '?code=' + code);
  }

  get fio(): string {
    return this.user ? this.user.lastName + (this.user.firstName ? ' ' + this.user.firstName[0] + '.' : '') + (this.user.middleName ? ' ' + this.user.middleName[0] + '.' : '') : '';
  }

  get email(): string {
    return this.user?.login;
  }

  getTwoFactorQrCode(): Observable<ITwoFactorQrCodeViewModel> {
    return this.http.get<ITwoFactorQrCodeViewModel>(this.baseAddress + 'Login/TwoFactorQrCode', { headers: this.authHttpHeaders });
  }

  confirmTwoFactorQrCode(code: string): Observable<string> {
    return this.http.post<string>(this.baseAddress + 'Login/TwoFactorQrCode', { code }, { headers: this.authHttpHeaders });
  }

  getHistoryItemDetail(urlPath: string, id: string): Observable<IAtpHistory> {
    return this.http.get<IAtpHistory>(this.baseAddress + urlPath.substr(4) + '/history/' + id, { headers: this.authHttpHeaders });
  }

  uploadFile(file: File): Observable<HttpEvent<{}>> {
    let formData = new FormData();
    formData.append('file', file, file.name);
    const req = new HttpRequest('POST', this.baseAddress + 'files/upload', formData, {
      reportProgress: true,
      headers: this.authHttpHeaders
    });
    return this.http.request(req);
  }

  getFile(id: string, needAuth = true): Observable<any> {
    let result = new Subject<any>();
    if ((new Date(this.tokenLifeTime) > new Date(new Date(Date.now())) && needAuth) || !needAuth) {
      window.open(this.baseAddress + 'Files/app/' + id + '?access_token=' + this.token);
      setTimeout(() => {
        result.next('ok');
      }, 100);
    } else {
      setTimeout(() => {
        result.error('reauth');
      }, 100);
    }
    setTimeout(() => {
      result.complete();
    }, 150);
    return result;
  }

  getRegistrationInfo(): Observable<IAtpAddEditInfo> {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + 'login/registration');
  }

  registration(data: any): Observable<string> {
    return this.http.post<string>(this.baseAddress + 'login/registration', data);
  }

  passwordRecovery(email: string): Observable<void> {
    return this.http.post<void>(this.baseAddress + `login/passwordRecovery/${email}`, null);
  }

  changePassword(token: string, data: any): Observable<void> {
    return this.http.post<void>(this.baseAddress + `login/changePassword?token=${token}`, data);
  }

  getHandbooks(type: string, pageNumber: number, pageSize: number, sortField: string, sortDirection: string, filtrationParams: string): Observable<IAtpPaginationDynamicTable<IAtpEntity>> {
    return this.http.get<IAtpPaginationDynamicTable<IAtpEntity>>(this.baseAddress + 'handbooks' + (type ? '/' + type : '') + '/' + pageNumber + '/' + pageSize + (sortField && sortDirection ? '/' + sortField + '/' + sortDirection : '') + (filtrationParams ? '?' + filtrationParams : ''), { headers: this.authHttpHeaders });
  }

  getHandbooksTree(type: string, id: any, allElements: boolean, pageNumber: number, pageSize: number, sortField: string, sortDirection: string, filtrationParams: string): Observable<IAtpPaginationDynamicTable<IAtpEntity>> {
    return this.http.get<IAtpPaginationDynamicTable<IAtpEntity>>(this.baseAddress + 'handbooks' + (type ? '/' + type : '') + '/' + (!id ? 'null' : id) + '/' + (allElements ? 'true' : 'false') + '/' + pageNumber + '/' + pageSize + (sortField && sortDirection ? '/' + sortField + '/' + sortDirection : '') + (filtrationParams ? '?' + filtrationParams : ''), { headers: this.authHttpHeaders });
  }

  getHandbooksNodeTree(type: string, id: any): Observable<AtpNodeTree[]> {
    return this.http.get<AtpNodeTree[]>(this.baseAddress + 'handbooks' + (type ? '/' + type : '') + '/Node/' + id, { headers: this.authHttpHeaders });
  }

  // movementHandbooksTree(type: string, id: any, newParentId: any): Observable<string> {
  //   return this.http.put<string>(this.baseAddress + 'handbooks' + (type ? '/' + type : '') + '/Movement/' + id + (newParentId ? '/' + newParentId : ''), null, {headers: this.authHttpHeaders});
  // }

  movementHandbooksTree(type: string, data: any): Observable<string> {
    return this.http.put<string>(this.baseAddress + 'handbooks' + (type ? '/' + type : '') + '/movement', data, { headers: this.authHttpHeaders });
  }

  getListHandbooks(): Observable<{ handbooks: Array<IAtpHandbook>, handbooksGroups: Array<IAtpHandbookGroup> }> {
    return this.http.get<{ handbooks: Array<IAtpHandbook>, handbooksGroups: Array<IAtpHandbookGroup> }>(this.baseAddress + 'listhandbooks', { headers: this.authHttpHeaders });
  }

  addInfoHandbooks(type: string): Observable<IAtpAddEditInfo> {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + 'handbooks' + (type ? '/' + type : '') + '/addInfo', { headers: this.authHttpHeaders });
  }

  editInfoHandbooks(type: string, id: any): Observable<IAtpAddEditInfo> {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + 'handbooks' + (type ? '/' + type : '') + '/editInfo/' + id.toString(), { headers: this.authHttpHeaders });
  }

  addHandbooks(type: string, body: any): Observable<string> {
    return this.http.post<string>(this.baseAddress + 'handbooks' + (type ? '/' + type : ''), body, { headers: this.authHttpHeaders });
  }

  editHandbooks(type: string, body: any): Observable<string> {
    return this.http.put<string>(this.baseAddress + 'handbooks' + (type ? '/' + type : ''), body, { headers: this.authHttpHeaders });
  }

  deleteHandbooks(type: string, id: any): Observable<boolean>;
  deleteHandbooks(type: string, ids: any[]): Observable<boolean>;
  deleteHandbooks(type: string, ids: any): Observable<boolean> {
    if (ids.length) {
      let params = new HttpParams();
      for (const id of ids) {
        params = params.append('ids', id);
      }
      return this.http.delete<boolean>(this.baseAddress + 'handbooks' + (type ? '/' + type : ''), {
        params: params,
        headers: this.authHttpHeaders
      });
    } else {
      return this.http.delete<boolean>(this.baseAddress + 'handbooks' + (type ? '/' + type : '') + '/' + ids.toString(), { headers: this.authHttpHeaders });
    }
  }

  enabledHandbooks(type: string, value: boolean, id: any): Observable<boolean>;
  enabledHandbooks(type: string, value: boolean, ids: any[]): Observable<boolean>;
  enabledHandbooks(type: string, value: boolean, ids: any): Observable<boolean> {
    let formData = new FormData();
    if (ids.length) {
      for (const id of ids) {
        formData.append('ids', id);
      }
    } else {
      formData.append('id', ids);
    }
    return this.http.put<boolean>(this.baseAddress + 'handbooks' + (type ? '/' + type : '') + '/enabled/' + value.toString(), formData, { headers: this.authHttpHeaders });
  }

  historyHandbooks(type: string, id: any, pageNumber: number, pageSize: number, sortField?: string, sortDirection?: string): Observable<IAtpPagination<IAtpHistory>> {
    return this.http.get<any>(this.baseAddress + 'handbooks' + (type ? '/' + type : '') + '/history/' + (id ? id.toString() : 'null') + '/' + pageNumber + '/' + pageSize + (sortField && sortDirection ? '/' + sortField + '/' + sortDirection : ''), { headers: this.authHttpHeaders });
  }

  historyHistory(type: string, id: any, pageNumber: number, pageSize: number, sortField?: string, sortDirection?: string): Observable<IAtpPagination<IAtpHistory>> {
    return this.http.get<any>(this.baseAddress + 'history/history/' + pageNumber + '/' + pageSize + (sortField && sortDirection ? '/' + sortField + '/' + sortDirection : ''), { headers: this.authHttpHeaders });
  }

  exportHistoryInCsv(): Observable<any> {
    let result = new Subject<any>();
    if (new Date(this.tokenLifeTime) > new Date(new Date(Date.now()))) {
      window.open(this.baseAddress + 'history/history/exportHistoryInCsv' + '?access_token=' + this.token);
      setTimeout(() => {
        result.next('ok');
      }, 100);
    } else {
      setTimeout(() => {
        result.error('reauth');
      }, 100);
    }
    setTimeout(() => {
      result.complete();
    }, 150);
    return result;
  }

  getLotsFiltersInfo(): Observable<IAtpFilterInfo[]> {
    return this.http.get<IAtpFilterInfo[]>(this.baseAddress + 'Lots/FiltersInfo', { headers: this.authHttpHeaders });
  }

  getLots(globalFilter: string, pageNumber: number, pageSize: number, sortField: string, sortDirection: string, filtrationParams: string): Observable<IAtpPaginationDynamicTable<IAtpEntity>> {
    //filtrationParams = filtrationParams ? filtrationParams + '&globalFilter=' + globalFilter : 'globalFilter=' + globalFilter;
    return this.http.get<IAtpPaginationDynamicTable<IAtpEntity>>(this.baseAddress + 'Lots/' + (this.user ? '' : 'anonymous/') + pageNumber + '/' + pageSize + (sortField && sortDirection ? '/' + sortField + '/' + sortDirection : '') + (filtrationParams ? '?' + filtrationParams : ''), { headers: this.authHttpHeaders });
  }

  getLotTemplatesList(): Observable<ILotTemplates> {
    return this.http.get<ILotTemplates>(this.baseAddress + 'Lots/Templates', { headers: this.authHttpHeaders });
  }

  getDetailLot(id: number): Observable<any> {
    return this.http.get<any>(this.baseAddress + 'Lots/' + (this.user ? '' : 'anonymous/') + 'Detail/' + id + (this.user ? '' : '/' + this.anonymousKey), { headers: this.authHttpHeaders });
  }

  addInfoLots(type: string, templateId: number) {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + 'Lots/addInfo?templateId=' + templateId, { headers: this.authHttpHeaders });
  }

  cloneInfoLots(id: number) {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + 'Lots/CloneInfo/' + id, { headers: this.authHttpHeaders });
  }

  editInfoLots(type: string, id: number) {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + 'Lots/editInfo/' + id.toString(), { headers: this.authHttpHeaders });
  }

  addLots(type: string, body: any): Observable<string> {
    return this.http.post<string>(this.baseAddress + 'Lots' + '?templateId=' + body.templateId, body, { headers: this.authHttpHeaders });
  }

  editLots(type: string, body: any): Observable<string> {
    return this.http.put<string>(this.baseAddress + 'Lots?id=' + body.id, body, { headers: this.authHttpHeaders });
  }

  setWinnerLot(lotId: number, userId: number): Observable<string> {
    return this.http.put<string>(this.baseAddress + 'offersManagement/setwinner', {
      lotId,
      userId
    }, { headers: this.authHttpHeaders });
  }

  setWinnerOnPositionsLot(data: any): Observable<string> {
    return this.http.put<string>(this.baseAddress + 'offersManagement/SetWinnerOnPositions', data, { headers: this.authHttpHeaders });
  }

  setPreWinnerOnPositionsLot(data: any): Observable<string> {
    return this.http.put<string>(this.baseAddress + 'offersManagement/SetPreWinnerOnPositions', data, { headers: this.authHttpHeaders });
  }

  closeLot(id: number, reason?: string): Observable<string> {
    return this.http.put<string>(this.baseAddress + 'offersManagement/Close/' + id + (reason ? '/' + reason : ''), null, { headers: this.authHttpHeaders });
  }

  prolongLot(id: number, date: Date, reasonId: number): Observable<string> {
    return this.http.put<string>(this.baseAddress + 'offersManagement/Prolong/' + id + '/', {
      date,
      reasonId
    }, { headers: this.authHttpHeaders });
  }

  cancelResultLot(id: number, reason: string) {
    return this.http.put<string>(this.baseAddress + 'offersManagement/cancelResult/' + id + '/' + reason, null, { headers: this.authHttpHeaders });
  }

  historyLots(type: string, id: any, pageNumber: number, pageSize: number, sortField: string, sortDirection: string): Observable<IAtpPagination<IAtpHistory>> {
    return this.http.get<any>(this.baseAddress + 'lots/history/' + (id ? id.toString() : 'null') + '/' + pageNumber + '/' + pageSize + (sortField && sortDirection ? '/' + sortField + '/' + sortDirection : ''), { headers: this.authHttpHeaders });
  }

  private getIdsHttpParams(name: string, ids: any[]): HttpParams {
    let params = new HttpParams();
    if (ids) {
      for (const id of ids) {
        params = params.append(name, id.toString());
      }
    }
    return params;
  }

  autocompleteLotType(value: string, excludedIds?: any[]): Observable<IAtpSelectItem[]> {
    let httpParams = this.getIdsHttpParams('excludedIds', excludedIds);
    httpParams = new HttpParams().append('value', value);
    return this.http.get<IAtpSelectItem[]>(this.baseAddress + 'Handbooks/LotTypes/Autocomplete', {
      params: httpParams,
      headers: this.authHttpHeaders
    });
  }

  autocompleteProducts(value: string, excludedIds?: any[]): Observable<IAutocompleteProduct[]> {
    let httpParams = this.getIdsHttpParams('excludedIds', excludedIds);
    httpParams = new HttpParams().append('value', value);
    return this.http.get<IAutocompleteProduct[]>(this.baseAddress + 'Handbooks/Products/Autocomplete', {
      params: httpParams,
      headers: this.authHttpHeaders
    });
  }

  autocompleteSection(value: string, excludedIds?: any[]): Observable<IAtpSelectItem[]> {
    let httpParams = this.getIdsHttpParams('excludedIds', excludedIds);
    httpParams = new HttpParams().append('value', value);
    return this.http.get<IAtpSelectItem[]>(this.baseAddress + 'Handbooks/Sections/Autocomplete', {
      params: httpParams,
      headers: this.authHttpHeaders
    });
  }

  autocompleteSections(value: string, excludedIds?: any[]): Observable<IAtpSelectItem[]> {
    return this.autocompleteSection(value, excludedIds);
  }

  autocompleteOrgStructure(value: string, excludedIds?: any[]): Observable<IAtpSelectItem[]> {
    let httpParams = this.getIdsHttpParams('excludedIds', excludedIds);
    httpParams = new HttpParams().append('value', value);
    return this.http.get<IAtpSelectItem[]>(this.baseAddress + 'Handbooks/OrgStructures/Autocomplete', {
      params: httpParams,
      headers: this.authHttpHeaders
    });
  }

  autocompleteUsers(value: string, excludedIds?: any[]): Observable<IAtpSelectItem[]> {
    let httpParams = this.getIdsHttpParams('excludedIds', excludedIds);
    httpParams = httpParams.append('value', value) || new HttpParams().append('value', value);
    return this.http.get<IAtpSelectItem[]>(this.baseAddress + 'users/Autocomplete/', {
      params: httpParams,
      headers: this.authHttpHeaders
    });
  }

  getUserNamesByIds(ids: any[]): Observable<IAtpSelectItem[]> {
    let httpParams = this.getIdsHttpParams('ids', ids);
    return this.http.get<IAtpSelectItem[]>(this.baseAddress + 'Users/NamesByIds/', {
      params: httpParams,
      headers: this.authHttpHeaders
    })
  }

  addInfoProductsFromTable(name: string): Observable<any> {
    return this.http.get<any>(this.baseAddress + 'handbooks/products/AddInfoFromTable/' + name, { headers: this.authHttpHeaders });
  }

  addProductsFromTable(type: string, body: any): Observable<any> {
    return this.http.post<any>(this.baseAddress + 'handbooks/products/addFromTable', body, { headers: this.authHttpHeaders });
  }

  getByIdsProductsAutocomplete(ids: string[]): Observable<IAtpSelectItem[]> {
    let httpParams = this.getIdsHttpParams('ids', ids);
    return this.http.get<IAtpSelectItem[]>(this.baseAddress + 'handbooks/products/byids', {
      params: httpParams,
      headers: this.authHttpHeaders
    });
  }

  getListUsers(): Observable<[string, string][]> {
    return this.http.get<[string, string][]>(this.baseAddress + 'listusersections', { headers: this.authHttpHeaders });
  }

  getUsers(type: string, pageNumber: number, pageSize: number, sortField: string, sortDirection: string, filtrationParams: string): Observable<IAtpPaginationDynamicTable<IAtpEntity>> {
    return this.http.get<IAtpPaginationDynamicTable<IAtpEntity>>(this.baseAddress + 'users/' + type + '/' + pageNumber + '/' + pageSize + (sortField && sortDirection ? '/' + sortField + '/' + sortDirection : '') + (filtrationParams ? '?' + filtrationParams : ''), { headers: this.authHttpHeaders });
  }

  editInfoUsers(type: string, id: number) {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + 'users/' + type + '/editInfo/' + id.toString(), { headers: this.authHttpHeaders });
  }

  addInfoUsers(type: string, id: number) {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + 'users/' + type + '/addInfo', { headers: this.authHttpHeaders });
  }

  addUsers(type: string, body: any): Observable<string> {
    return this.http.post<string>(this.baseAddress + 'users/' + type, body, { headers: this.authHttpHeaders });
  }

  editUsers(type: string, body: any): Observable<string> {
    return this.http.put<string>(this.baseAddress + 'users/' + type, body, { headers: this.authHttpHeaders });
  }

  deleteUsers(type: string, id: any): Observable<boolean>;
  deleteUsers(type: string, ids: any[]): Observable<boolean>;
  deleteUsers(type: string, ids: any): Observable<boolean> {
    if (ids.length) {
      let httpParams = new HttpParams();
      for (const id of ids) {
        httpParams = httpParams.append('ids', id);
      }
      return this.http.delete<boolean>(this.baseAddress + 'users' + (type ? '/' + type : ''), {
        params: httpParams,
        headers: this.authHttpHeaders
      });
    } else {
      return this.http.delete<boolean>(this.baseAddress + 'users' + (type ? '/' + type : '') + '/' + ids.toString(), { headers: this.authHttpHeaders });
    }
  }

  enabledUsers(type: string, value: boolean, id: any): Observable<boolean>;
  enabledUsers(type: string, value: boolean, ids: any[]): Observable<boolean>;
  enabledUsers(type: string, value: boolean, ids: any): Observable<boolean> {
    let formData = new FormData();
    if (ids.length) {
      for (const id of ids) {
        formData.append('ids', id);
      }
    } else {
      formData.append('id', ids);
    }
    return this.http.put<boolean>(this.baseAddress + 'users' + (type ? '/' + type : '') + '/enabled/' + value.toString(), formData, { headers: this.authHttpHeaders });
  }

  historyUsers(type: string, id: any, pageNumber: number, pageSize: number, sortField: string, sortDirection: string): Observable<IAtpPagination<IAtpHistory>> {
    return this.http.get<any>(this.baseAddress + 'users/' + type + '/history/' + (id ? id.toString() : 'null') + '/' + pageNumber + '/' + pageSize + (sortField && sortDirection ? '/' + sortField + '/' + sortDirection : ''), { headers: this.authHttpHeaders });
  }

  infoOfferAdd(lotId: number): Observable<any> {
    return this.http.get<any>(this.baseAddress + 'Offers/InfoAdd/' + lotId.toString(), { headers: this.authHttpHeaders });
  }

  infoOfferEdit(offerId: number): Observable<any> {
    return this.http.get<any>(this.baseAddress + 'Offers/InfoEdit/' + offerId.toString(), { headers: this.authHttpHeaders });
  }

  addOffer(body: any): Observable<any> {
    return this.http.post<any>(this.baseAddress + 'Offers', body, { headers: this.authHttpHeaders });
  }

  editOffer(offerId, body: any): Observable<any> {
    return this.http.put<any>(this.baseAddress + 'Offers/' + offerId, body, { headers: this.authHttpHeaders });
  }

  getManagementOffersInfo(lotId: number, stageTypeId: number = null): Observable<any> {
    return this.http.get<any>(this.baseAddress + 'offersManagement/Info/' + lotId.toString() + (stageTypeId ? '/' + stageTypeId : ''), { headers: this.authHttpHeaders });
  }

  getSpecificationPriceHistory(productId: number, page: number, pageSize: number, filter?: string): Observable<ISpecificationPriceHistory> {
    return this.http.get<ISpecificationPriceHistory>(this.baseAddress + 'offersManagement/PriceHistory?productId=' + productId + (filter != undefined ? '&filter=' + filter : '') + '&pageNumber=' + page + '&pageSize=' + pageSize, { headers: this.authHttpHeaders });
  }

  getViwedUserList<T>(lotId: number, page: number, pageSize: number): Observable<IAtpPaginationDynamicTable<T>> {
    return this.http.get<IAtpPaginationDynamicTable<T>>(this.baseAddress + 'lots/views/' + lotId + '?' + '&pageNumber=' + page + '&pageSize=' + pageSize, { headers: this.authHttpHeaders });
  }

  getOffersHistory(lotId: number): Observable<OffersHistory> {
    return this.http.get<any>(this.baseAddress + 'offersManagement/History/' + lotId.toString(), { headers: this.authHttpHeaders });
  }

  getProfileInfo(id?: number): Observable<IAtpAddEditInfo> {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + 'Login/ProfileInfo' + (id ? '/' + id : ''), { headers: this.authHttpHeaders });
  }

  getGlobazSections(): Observable<any> {
    return this.http.get<any>(this.baseAddress + 'Globaz/Sections', { headers: this.authHttpHeaders });
  }

  getGlobazInfo(inn: string): Observable<any> {
    return this.http.get<any>(this.baseAddress + 'Globaz/Info/' + inn, { headers: this.authHttpHeaders });
  }

  getGlobazPdf(id: string, sections: any): Observable<any> {
    const sectionValues = [];
    for (const key in sections) {
      if (sections[key]) {
        sectionValues.push(key);
      }
    }

    let params = this.getIdsHttpParams('sections', sectionValues);
    params = params.append('access_token', this.token);

    let result = new Subject<any>();
    if (new Date(this.tokenLifeTime) > new Date(Date.now())) {
      window.open(this.baseAddress + 'Globaz/Pdf/' + id + '?' + params.toString());
      setTimeout(() => {
        result.next('ok');
      }, 100);
    } else {
      setTimeout(() => {
        result.error('reauth');
      }, 100);
    }
    setTimeout(() => {
      result.complete();
    }, 150);
    return result;
  }

  changeProfile(body: any, type: string): Observable<string> {
    return this.http.put<string>(this.baseAddress + 'Login/' + type, body, { headers: this.authHttpHeaders });
  }

  getSubscriptionSectionProductsInfo(): Observable<IAtpAddEditInfo> {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + 'Login/SubscriptionSectionProductsInfo', { headers: this.authHttpHeaders });
  }

  getSubscriptionSectionProductsInfoByUserId(userId: number): Observable<IAtpAddEditInfo> {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + 'Login/SubscriptionSectionProductsInfo/' + userId, { headers: this.authHttpHeaders });
  }

  subscribeSectionProducts(body: any): Observable<string> {
    return this.http.put<string>(this.baseAddress + 'Login/SubscriptionSectionProducts', body, { headers: this.authHttpHeaders });
  }

  admittedParticipant(type: string, value: boolean, id: any, reason?: string): Observable<boolean>;
  admittedParticipant(type: string, value: boolean, ids: any[], reason?: string): Observable<boolean>;
  admittedParticipant(type: string, value: boolean, ids: any, reason?: string): Observable<boolean> {
    let formData = new FormData();
    if (ids.length) {
      for (const id of ids) {
        formData.append('ids[]', id);
      }
    } else {
      formData.append('id', ids);
    }
    if (reason) {
      formData.append('reason', reason);
    }
    return this.http.put<boolean>(this.baseAddress + 'users' + (type ? '/' + type : '') + '/admitted/' + value.toString(), formData, { headers: this.authHttpHeaders });
  }

  blackListParticipant(type: string, value: boolean, id: any, reason?: string): Observable<boolean>;
  blackListParticipant(type: string, value: boolean, ids: any[], reason?: string): Observable<boolean>;
  blackListParticipant(type: string, value: boolean, ids: any, reason?: string): Observable<boolean> {
    let formData = new FormData();
    if (ids.length) {
      for (const id of ids) {
        formData.append('ids[]', id);
      }
    } else {
      formData.append('id', ids);
    }
    if (reason) {
      formData.append('reason', reason);
    }
    return this.http.put<boolean>(this.baseAddress + 'users' + (type ? '/' + type : '') + '/blacklist/' + value.toString(), formData, { headers: this.authHttpHeaders });
  }

  blackListUserManagementOffers(value: boolean, lotId: number, userId: number, reason?: string): Observable<string> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('reason', reason);
    return this.http.put<string>(this.baseAddress + 'OffersManagement/BlackListUser/' + lotId + '/' + userId + '/' + value, null, {
      params: httpParams,
      headers: this.authHttpHeaders
    });
  }

  getNextStageInfo(lotId: number): Observable<IAtpAddEditInfo> {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + 'Lots/NextStageInfo/' + lotId, { headers: this.authHttpHeaders });
  }

  getFinishStageInfo(lotId: number): Observable<IAtpAddEditInfo> {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + 'Lots/InfoFinish/' + lotId, { headers: this.authHttpHeaders });
  }



  addNextStage(type: string, body: any): Observable<string> {
    return this.http.put<string>(this.baseAddress + 'Lots/AddNextStage/' + body.type + '/' + body.lotId, body, { headers: this.authHttpHeaders });
  }

  actionFinishLot(type: string, body: any): Observable<string> {
    return this.http.put<string>(this.baseAddress + 'Lots/Finish/' + body.lotId, body, { headers: this.authHttpHeaders });
  }

  getResultOffersExcel(id: number): Observable<any> {
    let result = new Subject<any>();
    if (new Date(this.tokenLifeTime) > new Date(new Date(Date.now()))) {
      window.open(this.baseAddress + 'OffersManagement/resultexcel/' + id + '?access_token=' + this.token);
      setTimeout(() => {
        result.next('ok');
      }, 100);
    } else {
      setTimeout(() => {
        result.error('reauth');
      }, 100);
    }
    setTimeout(() => {
      result.complete();
    }, 150);
    return result;
  }

  getProtocolExcel(id: number): Observable<any> {
    let result = new Subject<any>();
    if (new Date(this.tokenLifeTime) > new Date(new Date(Date.now()))) {
      window.open(this.baseAddress + 'OffersManagement/protocol/' + id + '?access_token=' + this.token);
      setTimeout(() => {
        result.next('ok');
      }, 100);
    } else {
      setTimeout(() => {
        result.error('reauth');
      }, 100);
    }
    setTimeout(() => {
      result.complete();
    }, 150);
    return result;
  }

  getConListExcel(id: number): Observable<any> {
    let result = new Subject<any>();
    if (new Date(this.tokenLifeTime) > new Date(new Date(Date.now()))) {
      window.open(this.baseAddress + 'OffersManagement/con-list/' + id + '?access_token=' + this.token);
      setTimeout(() => {
        result.next('ok');
      }, 100);
    } else {
      setTimeout(() => {
        result.error('reauth');
      }, 100);
    }
    setTimeout(() => {
      result.complete();
    }, 150);
    return result;
  }

  getTemplateOfferExcel(id: number): Observable<any> {
    let result = new Subject<any>();
    if (new Date(this.tokenLifeTime) > new Date(new Date(Date.now()))) {
      window.open(this.baseAddress + 'offers/templateexcel/' + id + '?access_token=' + this.token);
      setTimeout(() => {
        result.next('ok');
      }, 100);
    } else {
      setTimeout(() => {
        result.error('reauth');
      }, 100);
    }
    setTimeout(() => {
      result.complete();
    }, 150);
    return result;
  }

  getTemplateLotTableExcel(tableColumns: ITableColumn[], lotId?: number): Observable<any> {
    let params = new HttpParams();
    for (const tableColumn of tableColumns) {
      params = params.append('tableColumnNames', tableColumn.name).append('tableColumnTypes', tableColumn.type);
      if (lotId) {
        params = params.append('tableColumnIds', tableColumn.otherColumnId);
      }
    }

    let result = new Subject<any>();
    if (new Date(this.tokenLifeTime) > new Date(new Date(Date.now()))) {
      window.open(this.baseAddress + 'lots/templateexcel/' + (lotId ?? '') + '?access_token=' + this.token + '&' + params.toString());
      setTimeout(() => {
        result.next('ok');
      }, 100);
    } else {
      setTimeout(() => {
        result.error('reauth');
      }, 100);
    }
    setTimeout(() => {
      result.complete();
    }, 150);
    return result;
  }

  lotCommercialOrderFromExcel(file: File, tableColumns: ITableColumn[], lotId?: number, sectionId?: number): Observable<IOrder> {
    let body = new FormData();
    body.append('file', file, file.name);
    if (sectionId)
      body.append('sectionId', sectionId.toString());

    for (const tableColumn of tableColumns) {
      body.append('tableColumnNames', tableColumn.name);
      body.append('tableColumnTypes', tableColumn.type);
      body.append('tableColumnIds', tableColumn.otherColumnId);
    }
    return this.http.post<IOrder>(this.baseAddress + 'Lots' + '/СommercialOrderFromExcel/' + (lotId ?? ''), body, { headers: this.authHttpHeaders });
  }

  offerFromExcel(file: File, lotId: number): Observable<any> {
    let body = new FormData();
    body.append('file', file, file.name);
    return this.http.post<any>(this.baseAddress + 'Offers/OfferFromExcel/' + lotId, body, { headers: this.authHttpHeaders });
  }

  getMessages(type: string, pageNumber: number, pageSize: number, sortField: string, sortDirection: string, filtrationParams: string): Observable<IAtpPaginationDynamicTable<IAtpEntity>> {
    return this.http.get<IAtpPaginationDynamicTable<IAtpEntity>>(this.baseAddress + 'messages' + '/' + pageNumber + '/' + pageSize + (sortField && sortDirection ? '/' + sortField + '/' + sortDirection : '') + (filtrationParams ? '?' + filtrationParams : ''), { headers: this.authHttpHeaders });
  }


  getLotTemplates(type: string, pageNumber: number, pageSize: number, sortField: string, sortDirection: string, filtrationParams: string): Observable<IAtpPaginationDynamicTable<IAtpEntity>> {
    return this.http.get<IAtpPaginationDynamicTable<IAtpEntity>>(this.baseAddress + type + '/' + pageNumber + '/' + pageSize + (sortField && sortDirection ? '/' + sortField + '/' + sortDirection : '') + (filtrationParams ? '?' + filtrationParams : ''), { headers: this.authHttpHeaders });
  }

  addInfoLotTemplates(type: string): Observable<IAtpAddEditInfo> {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + type + '/addInfo', { headers: this.authHttpHeaders });
  }

  editInfoLotTemplates(type: string, id: any): Observable<IAtpAddEditInfo> {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + type + '/editInfo/' + id.toString(), { headers: this.authHttpHeaders });
  }

  addLotTemplates(type: string, body: any): Observable<string> {
    return this.http.post<string>(this.baseAddress + type, body, { headers: this.authHttpHeaders });
  }

  editLotTemplates(type: string, body: any): Observable<string> {
    return this.http.put<string>(this.baseAddress + type, body, { headers: this.authHttpHeaders });
  }

  deleteLotTemplates(type: string, id: any): Observable<boolean>;
  deleteLotTemplates(type: string, ids: any[]): Observable<boolean>;
  deleteLotTemplates(type: string, ids: any): Observable<boolean> {
    if (ids.length) {
      let params = new HttpParams();
      for (const id of ids) {
        params = params.append('ids', id);
      }
      return this.http.delete<boolean>(this.baseAddress + type, { params: params, headers: this.authHttpHeaders });
    } else {
      return this.http.delete<boolean>(this.baseAddress + type + '/' + ids.toString(), { headers: this.authHttpHeaders });
    }
  }

  enabledLotTemplates(type: string, value: boolean, id: any): Observable<boolean>;
  enabledLotTemplates(type: string, value: boolean, ids: any[]): Observable<boolean>;
  enabledLotTemplates(type: string, value: boolean, ids: any): Observable<boolean> {
    let formData = new FormData();
    if (ids.length) {
      for (const id of ids) {
        formData.append('ids', id);
      }
    } else {
      formData.append('id', ids);
    }
    return this.http.put<boolean>(this.baseAddress + type + '/enabled/' + value.toString(), formData, { headers: this.authHttpHeaders });
  }

  historyLotTemplates(type: string, id: any, pageNumber: number, pageSize: number, sortField?: string, sortDirection?: string): Observable<IAtpPagination<IAtpHistory>> {
    return this.http.get<any>(this.baseAddress + type + '/history/' + (id ? id.toString() : 'null') + '/' + pageNumber + '/' + pageSize + (sortField && sortDirection ? '/' + sortField + '/' + sortDirection : ''), { headers: this.authHttpHeaders });
  }


  getSettings(): Observable<IWorkingDays> {
    return this.http.get<IWorkingDays>(this.baseAddress + 'settings', { headers: this.authHttpHeaders });
  }

  saveSettings(obj): Observable<string> {
    return this.http.put<string>(this.baseAddress + 'settings', obj, { headers: this.authHttpHeaders });
  }

  getSettingsV2(): Observable<IAtpAddEditInfo> {
    return this.http.get<IAtpAddEditInfo>(this.baseAddress + 'v2/settings', { headers: this.authHttpHeaders });
  }

  editSettingsV2(type, obj): Observable<string> {
    return this.http.post<string>(this.baseAddress + 'v2/settings', obj, { headers: this.authHttpHeaders });
  }

  getDashboard(filterParams: string): Observable<Object> {
    return this.http.get<Object>(this.baseAddress + 'Lots/Dashboard?' + filterParams, { headers: this.authHttpHeaders });
  }

  getNotifications(): Observable<Notifications> {
    return this.http.get<Notifications>(this.baseAddress + 'Messages', { headers: this.authHttpHeaders });
  }

  getReports(type: string, pageNumber: number, pageSize: number, sortField: string, sortDirection: string, filtrationParams: string): Observable<IAtpPaginationDynamicTable<IAtpEntity>> {
    return this.http.get<IAtpPaginationDynamicTable<IAtpEntity>>(this.baseAddress + 'reports' + (type ? '/' + type : '') + '/' + pageNumber + '/' + pageSize + (sortField && sortDirection ? '/' + sortField + '/' + sortDirection : '') + (filtrationParams ? '?' + filtrationParams : ''), { headers: this.authHttpHeaders });
  }

  autocompleteId(value: string, excludedIds?: any[]): Observable<IAtpSelectItem[]> {
    let httpParams = this.getIdsHttpParams('excludedIds', excludedIds);
    httpParams = httpParams.append('value', value) || new HttpParams().append('value', value);
    return this.http.get<IAtpSelectItem[]>(this.baseAddress + 'users/Autocomplete/', {
      params: httpParams,
      headers: this.authHttpHeaders
    });
  }

  getReportExcel(type: string, filtrationParams: string): Observable<any> {
    let result = new Subject<any>();
    if (new Date(this.tokenLifeTime) > new Date(new Date(Date.now()))) {
      window.open(this.baseAddress + 'reports/' + type + '/excel?access_token=' + this.token + '&' + filtrationParams);
      setTimeout(() => {
        result.next('ok');
      }, 100);
    } else {
      setTimeout(() => {
        result.error('reauth');
      }, 100);
    }
    setTimeout(() => {
      result.complete();
    }, 150);
    return result;
  }
  getViwedUsersExcel(lotId: number): Observable<any> {
    let result = new Subject<any>();
    if (new Date(this.tokenLifeTime) > new Date(new Date(Date.now()))) {
      window.open(this.baseAddress + 'lots/views/excel/' + lotId + "?access_token=" + this.token);
      setTimeout(() => {
        result.next('ok');
      }, 100);
    } else {
      setTimeout(() => {
        result.error('reauth');
      }, 100);
    }
    setTimeout(() => {
      result.complete();
    }, 150);
    return result;
  }

  getReport(globalFilter: string, pageNumber: number, pageSize: number, sortField: string, sortDirection: string, filtrationParams: string): Observable<IAtpPaginationDynamicTable<IAtpEntity>> {
    return this.http.get<IAtpPaginationDynamicTable<IAtpEntity>>(this.baseAddress + 'reports/participation/count/' + pageNumber + '/' + pageSize + (sortField && sortDirection ? '/' + sortField + '/' + sortDirection : '') + (filtrationParams ? '?' + filtrationParams : ''), { headers: this.authHttpHeaders });
  }

  setService(value: boolean, ids: any): Observable<boolean> {
    let formData = new FormData();
    if (ids.length) {
      for (const id of ids) {
        formData.append('ids', id);
      }
    } else {
      formData.append('id', ids);
    }
    return this.http.put<boolean>(this.baseAddress + 'handbooks/products/setservice/' + value.toString(), formData, { headers: this.authHttpHeaders });
  }
}
